
import React, { useEffect, useRef, useState } from 'react';
import Banner from 'components/ScreenComponents/Banner';
import Industries from 'components/ScreenComponents/Industries';
import ApplySteps from 'components/ScreenComponents/ApplySteps';
import TextBlock from 'components/ScreenComponents/TextBlock';
import 'App.css';
import PropTypes from 'prop-types';
import { 
	AboutParagraph, 
	ConstructionParagraph, 
	TransportationParagraph,
	AgricultureParagraph
} from 'components/DataComponents/ChildComponents';
import {
	homeBannerMessages,
	financeBannerMessages,
	transportationBannerMessage,
	agricultureBannerMessage
} from 'components/DataComponents/BannerArrays';
import Hero from 'components/ScreenComponents/Hero';
import ApplyNow from 'components/Application/ApplyNow';
import Testimonials from 'components/ScreenComponents/Testimonials';
import ContactModal from 'components/ApplyNowChildren/ContactModal';
import ContactParagraph from 'components/ScreenComponents/Contact';

const Home = ({currentPage, onPageChange, setShowMenu}) => {
	const [ showModal, setShowModal ] = useState(false);
	const [ bannerMessage, setBannerMessage ] = useState(homeBannerMessages);
	const topAnchor = useRef(null);

	useEffect(() => {
		const bannerMessagesMap = {
			finance: financeBannerMessages,
			transportation: transportationBannerMessage,
			construction: transportationBannerMessage,
			agriculture: agricultureBannerMessage,
			applynow: homeBannerMessages,
			default: homeBannerMessages
		};

		setBannerMessage(bannerMessagesMap[currentPage] || bannerMessagesMap.default);
		setShowMenu(currentPage !== 'applynow');
	}, [currentPage]);

	const handleContactMe = () => {
		topAnchor.current.scrollIntoView({ behavior: 'smooth' });
		setShowModal(!showModal);
		
	};

	return (
		<div ref={topAnchor}>
			{ currentPage !== 'applynow' &&
				<>
					<Hero />
					<Banner messages={bannerMessage} />
				</>
			}
			{ (currentPage === 'home' || currentPage === 'finance') && 
				<Industries /> 
			}
			{ currentPage === 'about' && 
				<TextBlock> 
					<AboutParagraph />	
				</TextBlock>
			}
			{ currentPage === 'contact' && 
				<TextBlock> 
					<ContactParagraph 
						handleContactMe={handleContactMe}
					/>
				</TextBlock>
			}
			{ currentPage === 'transportation' && 
				<TextBlock>
					<TransportationParagraph />
				</TextBlock>
			}
			{ currentPage === 'construction' &&
				<TextBlock>
					<ConstructionParagraph />
				</TextBlock>
			}
			{ currentPage === 'agriculture' &&
				<TextBlock>
					<AgricultureParagraph />
				</TextBlock>
			}
			{ currentPage === 'testimonials' &&
				<TextBlock>
					<Testimonials />
				</TextBlock>
			}
			{ currentPage !== 'applynow' &&
				<>
					<ApplySteps onPageChange={onPageChange} />	
				</>
			}
			{ currentPage === 'applynow' &&
				<ApplyNow />
			}
			{ showModal &&
				<ContactModal isOpen={showModal} handleCloseClick={handleContactMe} />
			}
		</div>
	);
};

Home.propTypes = {
	currentPage: PropTypes.string,
	onPageChange: PropTypes.func.isRequired,
	setShowMenu: PropTypes.func.isRequired
};

export default Home;