import 'App.css';
import Nav from 'components/ScreenComponents/Nav';
import React, {useState} from 'react';
import Footer from 'components/ScreenComponents/Footer';
import Home from 'components/Home';

const App = () => {
	const [currentPage, setCurrentPage] = useState('home');
	const [showMenu, setShowMenu] = useState(true);

	return (
		<div className="App">
			<header className="App-header">
				<Nav 
					currentPage={currentPage} 
					onPageChange={setCurrentPage}
					showMenu={showMenu} 
				/>
			</header>
			<Home 
				currentPage={currentPage} 
				onPageChange={setCurrentPage} 
				setShowMenu={setShowMenu}	
			/>
			<Footer />
		</div>
	);
};

export default App;
