import React, { useState, useRef} from 'react'; 
import 'App.css';
import Request from 'components/ApplyNowChildren/Request';
import UploadAndDisplayImage from 'components/ApplyNowChildren/UploadAndDisplayImage';
import Consent from 'components/ApplyNowChildren/Consent';
import PersonalContact from 'components/ApplyNowChildren/PersonalContact';
import { emailPattern } from 'components/utils/Utils';
import emailjs from '@emailjs/browser';
import MissingInformationModal from 'components/ApplyNowChildren/MissingInformationModal';

const ApplyNow = () => {
	const [ applicationType, setApplicationType ] = useState('');
	const [selectedImage, setSelectedImage] = useState(null);
	const [shareholderID, setShareholderID] = useState(null);
	const [currentStep, setCurrentStep] = useState(1);
	const [applySteps, setApplySteps] = useState([
		{ step: 1, condition: '.active', name: 'Contact' },
		{ step: 2, condition: '', name: 'Application' },
		{ step: 3, condition: '', name: 'Request' },
		{ step: 4, condition: '', name: 'Consent' },
		{ step: 5, condition: '', name: 'Submit' }
	]);
	const stepperbar = useRef(null);
	const [validationErrors, setValidationErrors] = useState({});
	const [ modalOpen, setModalOpen ] = useState(false);

	const [requestValues, setRequestValues] = useState({
		'request-type': '',
		'amount': '',
		'equipment': ''
	});

	const [ consentValues, setConsentValues ] = useState({
		'signature': '',
		'date': ''
	});

	const [ pcFormValues, setPCFormValues ] = useState({
		'first-name': '',
		'last-name': '',
		'email': '',
		'phone': ''
	});

	const handleCloseClick = () => {
		setModalOpen(false);
	};

	const handlePCInputChange = (event) => {
		const { id, value } = event.target;
		setPCFormValues({
			...pcFormValues,
			[id]: value
		});
	};

	const handleRequestChange = (event) => {
		const { id, value } = event.target;
		setRequestValues({
			...requestValues,
			[id]: value
		});
	};

	const handleConsentInfoChange = (event) => {
		const { id, value } = event.target;
		setConsentValues({
			...consentValues,
			[id]: value
		});
	};

	const handleNextStep = () => {
		stepperbar.current.scrollIntoView({ behavior: 'smooth' });
		setCurrentStep((prevStage) => prevStage + 1);
		setApplySteps(applySteps.map((stage) => {
			if (stage.step === currentStep) {
				return { ...stage, condition: 'completed' };
			} else if (stage.step === currentStep + 1) {
				return { ...stage, condition: 'active' };
			} else {
				return stage;
			}
		}));
	};

	const handlePreviousStep = () => {
		stepperbar.current.scrollIntoView({ behavior: 'smooth' });
		setCurrentStep((prevStage) => prevStage - 1);
		setApplySteps(applySteps.map((stage) => {
			if (stage.step === currentStep) {
				return { ...stage, condition: '' };
			} else if (stage.step === currentStep - 1) {
				return { ...stage, condition: 'active' };
			} else {
				return stage;
			}
		}));	
	};

	const validateForm = (templateParams) => {
		const errors = {};

		if (!templateParams['email'] || !emailPattern.test(templateParams['email'])) {
			errors.email = 'Invalid email address';
		}
		if (!requestValues['request-type']) {
			errors.requestType = 'Request type is required';
		}
		if (!requestValues['amount']) {
			errors.amount = 'Amount is required';
		}
		if (!consentValues['signature']) {
			errors.signature = 'Signature is required';
		}
		if (!consentValues['date']) {
			errors.date = 'Date is required';
		}

		return {
			isValid: Object.keys(errors).length === 0,
			errors
		};
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const templateParams = {
			'first_name': pcFormValues['first-name'],
			'last_name': pcFormValues['last-name'],
			'email': pcFormValues['email'],
			'phone': pcFormValues['phone'],
			'request_type': requestValues['request-type'],
			'request_amount': requestValues['amount'],
			'equipment': requestValues['equipment'],
			'consent_signature': consentValues['signature'],
			'consent_date': consentValues['date'],
			'application_type': applicationType,
			'id_image': selectedImage,
		};
		const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
		const templateID = process.env.REACT_APP_EMAILJS_APPLICATIONTEMPLATE_ID;
		const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

		const { isValid, errors } = validateForm(templateParams);
		
		if (isValid) {
			emailjs
				.send(serviceID, templateID, templateParams, publicKey)
				.then(
					(error) => {
						console.log('FAILED...', error);
					},
				);
			window.location.href = '/';
			return;
		}
		else {
			setValidationErrors(errors);
			setModalOpen(true);
		}
	};

	return (
		<div className="apply-now">
			<h1>Apply Now</h1>		
			<div ref={stepperbar} className="stepper-wrapper">
				{applySteps.map((stage) => (
					<div key={stage.step} className={`stepper-item ${stage.condition}`}>
						<div className="step-counter">{stage.step}</div>
						<div className="step-name">{stage.name}</div>
					</div>
				))}
			</div>

			{ currentStep === 1 ? (
				<PersonalContact
					pcFormValues={pcFormValues}
					handlePCInputChange={handlePCInputChange}
				/>
			) : null }

			{ currentStep === 2 ? (
				<div className='center mb-1 mt-1'>
					<button 
						type='button'
						className={applicationType === 'personal' ? 'btn mx-1 mb-1 wide' : 'btn inverted mx-1 mb-1 wide'} 
						onClick={(event) => {
							event.preventDefault();
							setApplicationType('personal');}
						}
					>
					Personal
					</button>
					<button 
						type='button'
						className={applicationType === 'corporate' ? 'btn mx-1 wide' : 'btn inverted mx-1 wide'} 
						onClick={(event) => {
							event.preventDefault();
							setApplicationType('corporate');}
						}
					>
					Corporate
					</button>
				</div>
			) : null }

			{ currentStep === 3 ? (							
				<Request 
					request={requestValues}
					handleRequestChange={handleRequestChange}	
				/>) : null	
			}

			{ currentStep === 4 ? (
				<Consent
					consentValues={consentValues}
					handleConsentInfoChange={handleConsentInfoChange}
				/>
			) : null }

			{ currentStep === 5 ? ( 
				<>
					<UploadAndDisplayImage
						selectedImage={selectedImage}
						setSelectedImage={setSelectedImage}
						shareholderID={shareholderID}
						setShareholderID={setShareholderID} 
					/>
				</>
			) : null }

			<div className='center mb-1 mt-1'>
				{ currentStep > 1 ? (
					<button
						type='button'
						className='btn mx-1'
						onClick={(event) => {
							event.preventDefault();
							handlePreviousStep();
						}}
					>
					Previous
					</button>
				) : null }

				{ currentStep < 5 ? (
					<button
						type='button'
						className='btn mx-1'
						onClick={(event) => {
							event.preventDefault();
							handleNextStep();
						}}
					>
					Next
					</button>
				) : null }

				{ currentStep === 5 ? (
					<button
						type='button'
						className='btn inverted mx-1'
						onClick={(event) => {
							event.preventDefault();
							handleSubmit(event);
						}}
					>
					Submit
					</button>
				) : null }
			</div>

			{ modalOpen ? (
				<MissingInformationModal 
					isOpen={modalOpen} 
					handleCloseClick={handleCloseClick} 
					errors={validationErrors} 
				/>
			) : null }
		</div>
	);
};

export default ApplyNow;