import React from 'react';
import PropTypes from 'prop-types';

const MissingInformationModal = ({isOpen, handleCloseClick, errors}) => {
	return (
		<div className={isOpen ? 'outer-modal active' : 'outer-modal'}>
			<div className="inner-modal">
				<div className="header-modal flex s-b">
					<h1 className="w-a">Missing Information</h1>
					<button className="modal-close m-0" onClick={handleCloseClick}>X</button>
				</div>
				<div className="modal-content">
					<p>Please fill out all required fields before submitting your application.</p>
					<ul>
						{Object.keys(errors).map((key, index) => {
							return <li key={index}>{errors[key]}</li>;
						})}
					</ul>
					<button className="btn" onClick={handleCloseClick}>Close</button>
				</div>
			</div>
		</div>
	);
};

MissingInformationModal.propTypes = {
	isOpen: PropTypes.bool,
	handleCloseClick: PropTypes.func,
	errors: PropTypes.object
};

export default MissingInformationModal;