import React from 'react';
import PropTypes from 'prop-types';

const PersonalContact = ({pcFormValues,handlePCInputChange}) => {
	return (
		<div className='form-section'>
			<div className='form-row c-2'>
				<label htmlFor='first-name'>First Name</label>
				<input
					type='text'
					id='first-name'
					name='first-name'
					value={pcFormValues['first-name']}
					onChange={handlePCInputChange}
					required
				/>
			</div>
			<div className='form-row c-2'>
				<label htmlFor='last-name'>Last Name</label>
				<input
					type='text'
					id='last-name'
					name='last-name'
					value={pcFormValues['last-name']}
					onChange={handlePCInputChange}
					required
				/>
			</div>
			<div className='form-row c-2'>
				<label htmlFor='email'>Email</label>
				<input
					type='email'
					id='email'
					name='email'
					value={pcFormValues['email']}
					onChange={handlePCInputChange}
					required
				/>
			</div>
			<div className='form-row c-2'>
				<label htmlFor='phone'>Phone</label>
				<input
					type='tel'
					id='phone'
					name='phone'
					value={pcFormValues['phone']}
					onChange={handlePCInputChange}
					required
				/>
			</div>
		</div>
	);

};

PersonalContact.propTypes = {
	pcFormValues: PropTypes.object,
	handlePCInputChange: PropTypes.func
};

export default PersonalContact;