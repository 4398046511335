import React from 'react';
import PropTypes from 'prop-types';

const Consent = ({consentValues,handleConsentInfoChange}) => {
		
	const todaysDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');

	consentValues['date'] = todaysDate;

	return (

		<div className='form-section'>
			<h3 className='center'>Consent</h3>
			<div className="form-row c-1">
				<p>
				We the undersigned hereby authorize Fundit Capital Inc. (“Fundit Capital Inc.”) and any potential 3rd party credit grantors in connection with this Credit Application to collect, use, and disclose certain personal and business information from and about us (“Information”).
				</p>
				<p>Fundit Capital Inc. and any potential 3rd party credit grantors may collect Information from and/or disclose Information to Fundit Capital Inc. agents, affiliates, third party service providers, credit bureaus, credit reporting agencies, other credit grantors, financing partners, and/or any person we have or propose to have financial relations with as well as third parties who wish to become involved in the syndication of a loan, lease, or other investment in which Information is relevant, or who are involved in risk assessment or due diligence in the context of a financial transaction or proposed financial transaction.
				</p>
				<p>We also authorize any person whom Fundit Capital Inc. or any potential 3rd party credit grantors may contact in this regard to provide Information to Fundit Capital Inc. or to any potential 3rd party credit grantors. 
				</p>
				<p>We acknowledge that Fundit Capital Inc. financing partners may transfer and store Information in other jurisdictions and as a result, Information may be lawfully accessible to regulatory authorities in accordance with the laws of those jurisdictions. Fundit Capital Inc. or any potential 3rd party credit grantors may collect, use, and disclose our or other personal identifiers to verify and report credit information to credit bureaus or credit reporting agencies as well as to confirm our identities.
				</p>
				<p>By choosing to provide you with Information, we are consenting to its use in accordance with the Privacy Act which we may be view and obtain at any time at <a href="http://laws-lois.justice.gc.ca/eng/acts/P-21/index.html" target='_blank' rel="noreferrer">http://laws-lois.justice.gc.ca/eng/acts/P-21/index.html</a> FUNDIT CAPITAL INC. and any potential 3rd party credit grantors may keep a file containing the Information, whether or not the requested credit is granted.
				</p>
				<p>We understand that we have a general right to access and rectify the Information in this file by making a written request to FUNDIT CAPITAL INC. and to any potential 3rd party credit grantors.
				</p>
			</div>
			<div className="form-row c-2">
				<label htmlFor="signature">Customer Signature</label>
				<input 
					type="text"
					id="signature"
					value={consentValues['signature']}
					onChange={handleConsentInfoChange}
					required
				/>
			</div>
			<div className="form-row c-2">
				<label htmlFor="date">Dated</label>
				<input 
					type="text"
					id="date"
					value={consentValues['date']}
					onChange={handleConsentInfoChange}
					readOnly
				/>
			</div>
		</div>
	);
};

Consent.propTypes = {
	handleConsentInfoChange: PropTypes.func.isRequired,
	consentValues: PropTypes.object
};

export default Consent;