import React from 'react';
import 'App.css';
import HeroImg from 'assets/img/iStock-531265801.jpg';

const Hero = () => {
	return (
		<div className="hero-block">
			<img src={HeroImg} alt="hero" />
		</div>
	);
};

export default Hero;