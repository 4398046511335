import React, { useState } from 'react';
import PropTypes from 'prop-types';	
import emailjs from '@emailjs/browser';
import { emailPattern } from 'components/utils/Utils';


const ContactModal = ({isOpen, handleCloseClick}) => {
	const [ nameError, setNameError ] = useState(false);
	const [ emailError, setEmailError ] = useState(false);
	const [ phoneError, setPhoneError ] = useState(false);

	const sendEmail = (e) => {
	
		const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
		const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
		const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

		const templateParams = {
			name: e.name,
			email: e.email,
			phone: e.phone,
			message: e.message,
			from_name: 'Fundit Capital',
			reply_to: 'no reply',
		};

		emailjs
			.send(serviceID, templateID, templateParams, publicKey)
			.then(
				() => {
					handleCloseClick();
				},
				(error) => {
					console.log('FAILED...', error);
				},
			);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const email = event.target.email.value;
		const phone = event.target.phone.value;
		const name = event.target.name.value;
		const message = event.target.message.value;	

		if (!name) {
			setNameError(true);
			return;
		}
		else {
			setNameError(false);
		}

		if (!email && !phone) {
			setEmailError(true);
			setPhoneError(true);
			return;
		}
		else {
			setEmailError(false);
			setPhoneError(false);
		}

		if (email && !emailPattern.test(email)) {
			setEmailError(true);
			return;
		} else {
			setEmailError(false);
		}

		const formData = {
			name,
			email,
			phone,
			message
		};

		// Send form data to an email address
		sendEmail(formData);
	};

	return (
		<div className={isOpen ? 'outer-modal active' : 'outer-modal'}>
			<div className="inner-modal">
				<div className="header-modal flex s-b">
					<h1 className="w-a">Contact Me</h1>
					<button className="modal-close m-0" onClick={handleCloseClick}>X</button>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="form-row">
						<label htmlFor="name">Name</label>
						<input type="text" id="name" name="name" required />
						<div className={nameError ? 'error-message active' : 'error-message'}>Please enter a name</div>
					</div>
					<div className="form-row">
						<label htmlFor="email">Email</label>
						<input type="email" id="email" name="email" />
						<div className={emailError ? 'error-message active' : 'error-message'}>Please enter a valid email</div>
					</div>
					<div className="form-row">
						<label htmlFor="phone">Phone</label>
						<input type="tel" id="phone" name="phone" />
						<div className={phoneError ? 'error-message active' : 'error-message'}>Please enter a valid phone number</div>
					</div>
					<div className="form-row">
						<label htmlFor="message">Message</label>
						<textarea id="message" name="message" required></textarea>
					</div>
					<div className="form-row">
						<button className="btn m-0">Submit</button>
					</div>
				</form>
			</div>
		</div>
	);
};

ContactModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleCloseClick: PropTypes.func.isRequired,
};

export default ContactModal;