import React from 'react';
import Logo from 'assets/img/fundit-white-logo.png';

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-content">
				<div className="footer-logo">
					<img src={Logo} alt="logo" />
				</div>
				<div className="contact">
					<p>403-837-7488</p>
					<p>info@funditcap.com</p>
				</div>
				<nav>
					<ul>
						<li>FUNDIT Capital Inc.</li>
						<li>&copy; 2022</li>
						<li>All Rights Reserved</li>
					</ul>
				</nav>
			</div>
		</footer>
	);
};

export default Footer;