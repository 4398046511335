export const homeBannerMessages = [
	'Fast Approvals',
	'Flexible Terms',
	'Asset-based Lending',
];

export const financeBannerMessages = [
	'Term Loans',
	'Capital Leases',
	'Pre-approved Equipment Line',
	'Refinancing',
];

export const transportationBannerMessage = [
	'Term Loans',
	'Capital Leases',
	'Pre-approved lines of Credits',
	'Refinancing *'
];

export const agricultureBannerMessage = [
	'Term Loans',
	'Capital Leases',
	'Pre-approved lines of Credits',
	'Refinancing'
];