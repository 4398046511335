import React from 'react';
import PropTypes from 'prop-types';

const ContactParagraph = ({ handleContactMe }) => (
	<div className='contact-info'>
		<ul>
			<li>
				<button 
					className='btn'
					onClick={() => window.location.href = 'tel:403837488'}
				>
					403-837-7488
				</button>
			</li>
			<li>
				<button
					className='btn'
					onClick={() => window.location.href = 'mailto:info@funditcap.com'}
				>
					info@funditcap.com
				</button>
			</li>
			<li>
				<button
					className='btn'
					onClick={() => {handleContactMe();}}
				>
					Contact Me
				</button>
			</li>
		</ul>
	</div>
);

ContactParagraph.propTypes = {
	handleContactMe: PropTypes.func.isRequired,
};

export default ContactParagraph;