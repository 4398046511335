import React from 'react';
import PropTypes from 'prop-types';

const TextBlock = ({ children }) => {
	return (
		<div className='section'>
			<div>{children}</div>
		</div>
	);
};

TextBlock.propTypes = {
	children: PropTypes.node.isRequired,
};

export default TextBlock;