import 'App.css';
import React from 'react';
import PropTypes from 'prop-types';

const Banner = ({messages}) => {
	return (		
		<div className="banner">
			<h1>{messages.join(' • ')}</h1>
		</div>
	);

};

Banner.propTypes = {
	messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Banner;