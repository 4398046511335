import React from 'react';
import TransportationBg from 'assets/img/growth-capital-transportation-5.jpg';
import Construction from 'assets/img/growth-capital-construction-5.jpg';
import Agriculture from 'assets/img/growth-capital-agriculture-5.jpg';


export const AboutParagraph = () => (
	<div className='mr-1 ml-1 center'>
		<h1>About Us</h1>
		<p>With over 50 years of experience in finance and equipment sales, we can provide creative solutions and terms to support your business growth.</p>
		<p>At FUNDIT, we focus on long term relationships with our clients. We do this by building trust through reliability, attentiveness and being fully transparent in our business transactions.</p>
		<p>We take pride in delivering the best service to our clients.</p>
	</div>
);

export const TransportationParagraph = () => (
	<div className="industries-bg" style={{ backgroundImage: `url(${TransportationBg})`}} >
		<div className="two-column-with-image" >
			<h1>Transportation</h1>
			<ul className='ul-no-bullets'>
				<li>New Owner/Operators</li>
				<li>Owner/Operators</li>
				<li>Small Fleets: 1 &mdash; 10 trucks</li>
				<li>Large Fleets: 10 &mdash; 100+ trucks</li>
				<li>* On approved credit conditions</li>
			</ul>
		</div>
	</div>
);

export const ConstructionParagraph = () => (
	<div className="industries-bg" style={{ backgroundImage: `url(${Construction})`}} >
		<div className="two-column-with-image" >
			<h1>Construction</h1>
			<ul className='ul-no-bullets'>
				<li>Start Ups</li>
				<li>Small Business 1 &mdash; 5 pieces of Equipment</li>
				<li>Medium 5 &mdash; 15 pieces of equipment</li>
				<li>Large Company 15 &mdash; 100 + pieces of equipment</li>
				<li>* On approved credit conditions</li>
			</ul>
		</div>
	</div>
);

export const AgricultureParagraph = () => (
	<div className="industries-bg" style={{ backgroundImage: `url(${Agriculture})`}} >
		<div className="two-column-with-image">
			<div>
				<h1>Agriculture</h1>
				<h2>Choice of loan or lease</h2>
				<ul className='ul-no-bullets'>
					<li>Monthly/Quarterly/Semi-Annual/Annual payments</li>
					<li>Available for up to 10 year terms</li> 
				</ul>
			</div>
			<div>
				<ul className='ul-no-bullets'>
					<li>Competitive program rates</li>
					<li>Minimal paperwork and fast turnaround times </li>
					<li>Work with people who understand agriculture</li>
					<li>Variable or fixed finance rates</li>
					<li>Security taken on equipment being financed </li>
					<li>Competitive down payment on loans</li>
					<li>* On approved credit conditions</li>
				</ul>
			</div>
		</div>
	</div>
);
