import React from 'react';
import PropTypes from 'prop-types';

const UploadAndDisplayImage = ({selectedImage, setSelectedImage}) => {

	return (
		<div className="form-section">
			<div className="c-1 form-row center">
				<input
					className='btn desktop-only'
					type="file"
					name="myImage"
					onChange={(event) => {
						setSelectedImage(event.target.files[0]); 
					}}
				/>			
				<input 
					className='btn mobile-only' 
					type="file" 
					accept="image/*" 
					capture="environment"
					onChange={(event) => {
						setSelectedImage(event.target.files[0]);
					}} 
				></input>
			</div>
			{selectedImage && (
				<>
					<div className="c-1 form-row center">
						<img
							alt="not found"
							width={'250px'}
							src={URL.createObjectURL(selectedImage)}
						/>
					</div>
					<div className="c-1 form-row center">
						<button className="btn inverted" onClick={() => setSelectedImage(null)}>Remove</button>
					</div>
				</>
			)}
		</div>
	);
};

UploadAndDisplayImage.propTypes = {
	selectedImage: PropTypes.object,
	setSelectedImage: PropTypes.func.isRequired
};

export default UploadAndDisplayImage;