import React from 'react';
import 'App.css';
import Launch from 'assets/img/launch.png';
import Approved from 'assets/img/approved.png';
import Applications from 'assets/img/application.png';
import PropTypes from 'prop-types';

const ApplySteps = ({onPageChange}) => {
	return (
		<div className='section bg-grey'>
			<h1>Apply in 3 Easy Steps</h1>
			<div className="apply-steps-grid">
				<div className="apply-step">
					<div className="apply-step-number">
						<img src={Applications} alt="Apply for Financing" />
					</div>
					<div className="apply-step-text">Credit Application</div>
				</div>
				<div className="apply-step">
					<div className="apply-step-number">
						<img src={Approved} alt="Approval" />
					</div>
					<div className="apply-step-text">Approval</div>
				</div>
				<div className="apply-step">
					<div className="apply-step-number">
						<img src={Launch} alt="Launch" />
					</div>
					<div className="apply-step-text">Go to Work</div>
				</div>
			</div>
			<div>
				<button onClick={() => onPageChange('applynow')} id="apply-cta" className="btn">Apply Now</button>			
			</div>
		</div>
	);
};

ApplySteps.propTypes = {
	onPageChange: PropTypes.func.isRequired
};

export default ApplySteps;