import 'App.css';
import React from 'react';
import Transportation from 'assets/img/growth-capital-transportation-6.jpg';
import Construction from 'assets/img/growth-capital-construction-5.jpg';
import Agriculture from 'assets/img/growth-capital-agriculture-2.jpg';
import Forestry from 'assets/img/forestry.jpg';
import OilAndGas from 'assets/img/oilandgas.jpg';
import Industrial from 'assets/img/industrial.jpg';

const Industries = () => {

	return (
		<div className="section">
			<h1>Industries we serve</h1>
			<div className="industries-grid">
				<div className="industry-box" >
					<img src={Transportation} alt="Transportation" />
					<div className="industry-text">Transportation</div>
				</div>
				<div className="industry-box">
					<img src={Construction} alt="Construction" />
					<div className="industry-text">Construction</div>
				</div>
				<div className="industry-box">
					<img src={Agriculture} alt="Agriculture" />
					<div className="industry-text">Agriculture</div>
				</div>
				<div className="industry-box">
					<img src={Forestry} alt="Forestry" />
					<div className="industry-text">Forestry</div>
				</div>
				<div className="industry-box">
					<img src={OilAndGas} alt="Oil and Gas" />
					<div className="industry-text">Oil and Gas</div>
				</div>
				<div className="industry-box">
					<img src={Industrial} alt="Industrial" />
					<div className="industry-text">Industrial</div>
				</div>
			</div>
		</div>
	);
};

export default Industries;