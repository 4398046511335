import React from 'react';
import 'App.css'; 
import Logo from 'assets/img/fundit-white-logo.png';
import PropTypes from 'prop-types';

const Nav = ({ onPageChange, showMenu}) => {

	const toggleMenu = () => {
		const menuToggleInput = document.getElementById('menuToggleInput');
		if (menuToggleInput) {
			menuToggleInput.checked = false;
		}
	};

	return (
		<nav className="navbar">
			<div className="header-logo" onClick={() => onPageChange('home')}>
				<img src={Logo} alt="logo" />
			</div>
			{ showMenu && 
				<div id='menuToggle'>
					<input id='menuToggleInput' type="checkbox"/>
					<span></span>
					<span></span>
					<span></span>
					<ul id='menu'>
						<li
							className='mobile-only'
							onClick={() => {
								onPageChange('applynow');
								toggleMenu();
							} }><a href="#">Apply Now</a>
						</li>
						<li className='mobile-only'><a href="tel:4038377488" alt="4038377488">Call now</a></li>					
						<li 
							onClick={() => {
								onPageChange('about');
								toggleMenu();
							}}
						>
							<a href='#'>About</a>
						</li>
						<li 
							onClick={() => {
								onPageChange('finance');
								toggleMenu();
							}}
						>
							<a href="#">Industries</a>
						</li>
						<li 
							onClick={() => {
								onPageChange('testimonials');
								toggleMenu();
							}}
						>
							<a href="#">Testimonials</a>
						</li>
						<li 
							onClick={() => {
								onPageChange('contact');							
								toggleMenu();
							}}
						>
							<a href='#'>Contact</a>
						</li>
					</ul>
					<button onClick={() => onPageChange('applynow')} id="header-cta" className="btn">Apply Now</button>
				</div>}
		</nav>
	);
};

Nav.propTypes = {
	onPageChange: PropTypes.func.isRequired,
	showMenu: PropTypes.bool.isRequired
};

export default Nav;