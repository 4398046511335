import React from 'react';
import PropTypes from 'prop-types';

const testimonials = [
	{
		name: 'Daniel Novack',
		text: 'FUNDIT Capital Inc. helped with the transition from being a driver to an owner/op, they have now helped grow my fleet to 10 trucks by providing the proper financial and insurance solutions for my business.',
		company: 'Fast Pace Trucking Ltd',
	},
];

const Testimonial = ({ name, text, company }) => (
	<div className="testimonial-inner">
		<p>&quot;{text}&quot;</p>
		<p style={{textAlign:'right'}}>&mdash; {name}, {company}</p>
	</div>
);

Testimonial.propTypes = {
	name: PropTypes.string,
	text: PropTypes.string,
	company: PropTypes.string,
};

const Testimonials = () => (
	<div className="testimonials-block">
		{testimonials.map((testimonial, index) => (
			<Testimonial 
				key={index}
				name={testimonial.name}
				text={testimonial.text}
				company={testimonial.company}
			/>
		))}
	</div>
);

export default Testimonials;